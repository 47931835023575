import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0b48e1b5 = () => interopDefault(import('../src/pages/404.vue' /* webpackChunkName: "pages/404" */))
const _f795d584 = () => interopDefault(import('../src/pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _64179f83 = () => interopDefault(import('../src/pages/berth/index.vue' /* webpackChunkName: "pages/berth/index" */))
const _bdcfc7e2 = () => interopDefault(import('../src/pages/main/index.vue' /* webpackChunkName: "pages/main/index" */))
const _cb704fea = () => interopDefault(import('../src/pages/arrival/add/index.vue' /* webpackChunkName: "pages/arrival/add/index" */))
const _0e6ebde0 = () => interopDefault(import('../src/pages/arrival/export/index.vue' /* webpackChunkName: "pages/arrival/export/index" */))
const _8f6eb6de = () => interopDefault(import('../src/pages/arrival/import/index.vue' /* webpackChunkName: "pages/arrival/import/index" */))
const _20488e8a = () => interopDefault(import('../src/pages/arrival/list/index.vue' /* webpackChunkName: "pages/arrival/list/index" */))
const _fe67067a = () => interopDefault(import('../src/pages/arrival/schedule/index.vue' /* webpackChunkName: "pages/arrival/schedule/index" */))
const _4aeda258 = () => interopDefault(import('../src/pages/arrival/search/index.vue' /* webpackChunkName: "pages/arrival/search/index" */))
const _0639d678 = () => interopDefault(import('../src/pages/setting/berth/index.vue' /* webpackChunkName: "pages/setting/berth/index" */))
const _59d1cfca = () => interopDefault(import('../src/pages/setting/center/index.vue' /* webpackChunkName: "pages/setting/center/index" */))
const _fb84ba26 = () => interopDefault(import('../src/pages/setting/information/index.vue' /* webpackChunkName: "pages/setting/information/index" */))
const _3fe26d2b = () => interopDefault(import('../src/pages/setting/role/index.vue' /* webpackChunkName: "pages/setting/role/index" */))
const _0d6d1b80 = () => interopDefault(import('../src/pages/setting/user/index.vue' /* webpackChunkName: "pages/setting/user/index" */))
const _248a3e0c = () => interopDefault(import('../src/pages/arrival/list/detail/index.vue' /* webpackChunkName: "pages/arrival/list/detail/index" */))
const _256c9682 = () => interopDefault(import('../src/pages/arrival/search/detail/index.vue' /* webpackChunkName: "pages/arrival/search/detail/index" */))
const _f45b22f0 = () => interopDefault(import('../src/pages/plan/list/edit.vue' /* webpackChunkName: "pages/plan/list/edit" */))
const _72abf3c0 = () => interopDefault(import('../src/pages/arrival/list/detail/edit.vue' /* webpackChunkName: "pages/arrival/list/detail/edit" */))
const _ff6601e2 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _0b48e1b5,
    name: "404"
  }, {
    path: "/auth",
    component: _f795d584,
    name: "auth"
  }, {
    path: "/berth",
    component: _64179f83,
    name: "berth"
  }, {
    path: "/main",
    component: _bdcfc7e2,
    name: "main"
  }, {
    path: "/arrival/add",
    component: _cb704fea,
    name: "arrival-add"
  }, {
    path: "/arrival/export",
    component: _0e6ebde0,
    name: "arrival-export"
  }, {
    path: "/arrival/import",
    component: _8f6eb6de,
    name: "arrival-import"
  }, {
    path: "/arrival/list",
    component: _20488e8a,
    name: "arrival-list"
  }, {
    path: "/arrival/schedule",
    component: _fe67067a,
    name: "arrival-schedule"
  }, {
    path: "/arrival/search",
    component: _4aeda258,
    name: "arrival-search"
  }, {
    path: "/setting/berth",
    component: _0639d678,
    name: "setting-berth"
  }, {
    path: "/setting/center",
    component: _59d1cfca,
    name: "setting-center"
  }, {
    path: "/setting/information",
    component: _fb84ba26,
    name: "setting-information"
  }, {
    path: "/setting/role",
    component: _3fe26d2b,
    name: "setting-role"
  }, {
    path: "/setting/user",
    component: _0d6d1b80,
    name: "setting-user"
  }, {
    path: "/arrival/list/detail",
    component: _248a3e0c,
    name: "arrival-list-detail"
  }, {
    path: "/arrival/search/detail",
    component: _256c9682,
    name: "arrival-search-detail"
  }, {
    path: "/plan/list/edit",
    component: _f45b22f0,
    name: "plan-list-edit"
  }, {
    path: "/arrival/list/detail/edit",
    component: _72abf3c0,
    name: "arrival-list-detail-edit"
  }, {
    path: "/",
    component: _ff6601e2,
    name: "index"
  }, {
    path: "*",
    component: _0b48e1b5,
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
